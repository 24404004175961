import {
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
  AuthenticationResult,
} from "@azure/msal-browser";

export const ApiScope = "https://api.dsit.ouihelp.fr/Main";

const configuration: Configuration = {
  auth: {
    clientId: "40a9e253-0508-43cc-8aa3-d165502bff47",
    authority: "https://login.microsoftonline.com/f907685c-8d81-492b-ae7b-fbeff9057dd4",
  },
};

const publicClientApplication = new PublicClientApplication(configuration);

const getCreateSessionAccessToken = async (): Promise<AuthenticationResult> => {
  const request: SilentRequest = {
    scopes: [ApiScope],
  };

  try {
    return await publicClientApplication.acquireTokenSilent(request);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.log('Silent "acquire token" failed', error);
      return await publicClientApplication.acquireTokenPopup(request);
    }
    throw error;
  }
};

export type JobType = {
  value: string;
  label: string;
  group: string;
  assignable: boolean;
};

export type ZoneType = { zone_slug: string; zone_name: string };

export type AgencyType = {
  agency_uuid: string;
  zone_slug: string;
  agency_name: string;
};

export type AgencyOptionType = { value: string; label: string };

export type PostalCodeType = {
  insee_code: string;
  postal_code: string;
  city_name: string;
  agency_uuid: string | null;
  core_agency: boolean | null;
  close_core_agency: boolean | null;
};

export let staticDataPromiseResolver: () => void;
const staticDataPromise = new Promise<undefined>(resolve => {
  staticDataPromiseResolver = () => resolve(undefined);
});

export const AppConfig: {
  baseUrl: string;

  // MSAL section
  publicClientApplication: PublicClientApplication;
  getCreateSessionAccessToken: () => Promise<AuthenticationResult>;
  logout: () => void;

  // Static data section
  staticDataPromise: Promise<undefined>;
  jobs: JobType[];
  zones: ZoneType[];
  agencies: AgencyType[];
  agenciesOptions: { value: string; label: string; assignable: boolean }[];
} = {
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  publicClientApplication,
  getCreateSessionAccessToken,
  logout: () =>
    publicClientApplication.logoutRedirect({
      onRedirectNavigate: () => false,
    }),
  staticDataPromise,
  jobs: [],
  zones: [],
  agencies: [],
  agenciesOptions: [],
};

export const getAgencyNameFromUuid = (agencyUuid: any): string => {
  return AppConfig.agencies.find(a => a.agency_uuid === agencyUuid)?.agency_name || "";
};
